<template>
  <div>
    <b-card
      id="pdfGenerateDiv"
      ref="pdfGenerateDivRef"
      no-body
      class="invoice-preview-wrapper"
    >
      <div>
        <div
          class="card__inner"
        >
          <table style="width: 100%; page-break-after: auto; border-collapse:separate; border-spacing: 10px 5px;">
            <thead>
              <tr>
                <td>
                  <!-- Header -->
                  <b-card-body class="invoice-padding pb-0">

                    <div class="d-flex justify-content-between flex-md-row invoice-spacing mt-0">
                      <div>
                        <div class="logo-wrapper">
                          <img
                            style="height:60px;object-fit:contain;"
                            src="/nirvana-memorial-garden-logo.png"
                            alt="Logo"
                            crossorigin="anonymous"
                          >
                        </div>
                      </div>
                      <div
                        style="min-width: fit-content; width: fit-content;"
                        class="px-2"
                      >
                        <h4 style="text-align: center; text-transform: uppercase; color: #000000; font-weight: bold; font-size: 12px; line-height: 18px;">
                          <strong style="font-weight:bold;">Calendar</strong>
                        </h4>
                      </div>
                      <div class="mt-md-0 mt-2 text-right">
                        <span style="margin-top: 40px; display: inline-block; font-weight:normal;font-size:11px;">{{ dateFormatWithDay(date) }}</span>
                      </div>
                    </div>
                  </b-card-body>

                  <!-- Spacer -->
                  <hr
                    class="invoice-spacing"
                    style="margin-top:25px!important"
                  >
                </td>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(event, index) in serviceRequests"
                :key="index"
              >
                <td>
                  <!-- Invoice Client & Payment Details -->
                  <b-card-body
                    class="invoice-padding pt-2 main__body po__create preview_order_pdf operation__calendar"
                  >
                    <b-row>
                      <b-col
                        cols="12"
                        class="item__description_table"
                      >
                        <div>
                          <p
                            class="check_field mt-50"
                            style="margin: 0;"
                          >
                            <!-- eslint-disable -->
                            <span
                                class="semi-bold"
                                style="font-size:11px; font-weight: normal; color:#000000; line-height: 20px; margin:0 0 4px;"
                                v-html="resolveTitle(event)"
                            />
                            <!-- eslint-enable -->
                          </p>
                          <p style="font-size:11px; font-weight:normal; color:#333333; line-height: 16px; margin:0 0 8px; ">
                            {{ dateFormatWithShortDay(event.date) }}
                          </p>
                          <p style="font-size:11px; font-weight:normal; color:#333333; line-height: 16px; margin:0 0 4px; ">
                            <span
                              class="semi-bold"
                              style="display:inline-block; font-weight:bold;"
                            >Location:</span> {{ event.location || '-' }}
                          </p>
                          <p style="font-size:11px; font-weight:normal; color:#333333; line-height: 16px; margin:0 0 4px; ">
                            <span class="text-uppercase">Agent:</span> {{ event.serviceForm.agentName || '-' }} {{ event.serviceForm.agentContact || '-' }}
                          </p>
                          <p style="font-size:11px; font-weight:normal; color:#333333; line-height: 16px; margin:0 0 4px; ">
                            <span class="text-uppercase">CUSTOMER:</span> {{ event.serviceForm.customerName || '-' }} {{ event.serviceForm.customerContact || '-' }}
                          </p>
                          <p style="font-size:11px; font-weight:normal; color:#333333; line-height: 16px; margin:0 0 4px; ">
                            <span class="text-uppercase">ASSIGNEE:</span>
                            <span v-if="event.assignees.length">
                              <span
                                v-for="(user, key) in event.assignees"
                                :key="key"
                              >
                                {{ user.name }}<span v-if="key + 1 != event.assignees.length">, </span>
                              </span>
                            </span>
                            <span v-else> -</span>
                          </p>
                        </div>
                      </b-col>
                    </b-row>
                  </b-card-body>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BCardBody,
} from 'bootstrap-vue'
import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardBody,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      date: moment().format('DD/MM/YYYY'),
      serviceRequests: [],
    }
  },
  mounted() {
    const { date } = this.$route.query
    const selectedWorkflows = JSON.parse(this.$route.query.selectedWorkflows)
    this.$http.post('download/service-tasks/show', {
      date,
      selectedWorkflows,
    })
      .then(response => {
        this.date = date
        this.serviceRequests = response.data.serviceRequests ?? []
      })
  },
  methods: {
    resolveTitle(event) {
      const titleString = `${event.updatedTitle ? event.updatedTitle : event.title}${event.paymentStatus ? ` - ${event.paymentStatus}` : ''}`
      if (/[\u3040-\u30ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff\uff66-\uff9f]/.test(titleString)) {
        return `<span class="firefox_item with_chainese" style="font-weight:bold; display:inline-block; line-height:16px; vertical-align:middle;">${this.timeFormatFromDateString(event.date)}</span> <span style="font-weight:bold; display:inline-block; line-height:16px; vertical-align:middle; margin-top:0px">${titleString}</span>`
      }

      return `<span class="firefox_item" style="font-weight:bold; display:inline-block; line-height:16px; vertical-align:middle;">${this.timeFormatFromDateString(event.date)}</span> <span style="font-weight:bold; display:inline-block; line-height:16px; vertical-align:middle; margin-top:0px">${titleString}</span>`
    },
  },
}
</script>

<style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';

    body div#app.semi-dark-layout {
        background: #fff !important;
        background-repeat: no-repeat !important;
        background-position: center bottom !important;
        background-size: 100% auto !important;
    }
    body {
        background: #fff !important;
    }
    .card {
        box-shadow: none !important;
    }
</style>
<style>
    @media print {
        html, body {
            height: 99%;
        }
    }
</style>
